import React, { Component } from 'react'
import loadable from '@loadable/component'

import Loader from "../components/loader";

const Head = loadable(() => import("../components/head"));
const InnerLayout = loadable(() => import('../components/Innerlayout'));

export class TermsConditions extends Component {

    render() {

        return (
            <>
                <InnerLayout header='Main' fallback={<Loader />}>
                    <Head
                        type="Website"
                        fallback={<Loader />}
                        path="/terms-and-conditions/"
                        title="Terms of Use and Conditions - Softtik Technologies"
                        description="These terms of use and conditions highlight rules for using Softtik Technologies’ website, any of its properties, content, and references."
                    />
                    <main onWheel={this.onScroll}>
                        <div id="wrapper">
                            <div className="collection-page privacy-policy-page">
                                {/* Banner */}
                                <section className="banner-sec">
                                    <div className="banner-content">
                                        <div className="auto-container">
                                            <div className='row'>
                                                <div className="col-lg-8 offset-lg-2 col-md-12 col-sm-12 ">
                                                    <div className='banner-text wow fadeIn' data-wow-delay="0.3s">
                                                        <h1>Terms & Conditions </h1>
                                                        <p>Welcome to the Softtik Technologies!</p>
                                                        <p>These terms and conditions highlight the set of rules for using Softtik Technologies’ website. The main headquarter of Softtik Technologies is located in Pakistan.  </p>
                                                        <p>If you visit this website, we assume you accept the terms and conditions explained on this page. You should not visit this Softtik website if you do not accept our terms and conditions.</p>
                                                        <p>The terminology applicable to the privacy policy, terms and conditions, and other agreements are as follows: </p>
                                                        <p>“You,” “your,” and “client” refers to the person using Softtik Technologies’ website. The terms like “Our,” “we,” “us,” and company refer to Softtik Technologies. “Party” or “parties' ' may refer to both client and the company. Any other use of the terms in the capitalization, he/she, singular, plural, or they, can be considered interchangeable.</p>

                                                        <h4>Cookies</h4>
                                                        <p>We utilize the cookies, and by visiting Softtik Technologies’ website, you automatically agree to the company's privacy policy.</p>
                                                        <p>Most websites employ cookies to extract the website visitor details for each visit. Our website utilizes cookies to offer our users a unique and interactive experience. </p>

                                                        <h4>License</h4>
                                                        <p>Softtik Technologies and/or its licensor holds the intellectual property rights of the content and material on this website. Anyone can visit our website https://softtik.com/ for personal use under these terms and conditions.</p>

                                                        <h5>You must not: </h5>
                                                        <ul className="list-about style-two">
                                                            <li>Republish the content on www.softtik.com.</li>
                                                            <li>Sub-license, sell or rent the content of this website. </li>
                                                            <li>Duplicate or rewrite the content of this website.  </li>
                                                            <li>Distribute the content of Softtik’s website.</li>
                                                        </ul>

                                                        <h4>Hyperlink to Our Website</h4>
                                                        <p>Without prior approval, the following firms or organizations can link to Softtik’s website:</p>
                                                        <ul className="list-about style-two">
                                                            <li>Search Engines</li>
                                                            <li>Government institutions </li>
                                                            <li>News agencies </li>
                                                            <li>Directory distributors can also link to our website as they can link to the listed businesses.</li>
                                                        </ul>
                                                        <p>Such firms and agencies can link to our website as long as:</p>
                                                        <ol>
                                                            <li>Link is not deceptive in any way </li>
                                                            <li>The link does not include false endorsements or sponsorship of the linking party’s product.</li>
                                                            <li>The linking party has the same context as Softtik’s website.</li>
                                                        </ol>
                                                        <p>We can also consider approving the links of the following firms:</p>
                                                        <ul className="list-about style-two">
                                                            <li>Businesses/firms which are known for their information sources.</li>
                                                            <li>Community website, or we can say dot.com sites. </li>
                                                            <li>Charity firms or groups</li>
                                                            <li>Internet portals</li>
                                                            <li>Law, accounting, consulting firms</li>
                                                            <li>Trade agencies and educational institutes</li>
                                                        </ul>
                                                        <p>We can consider approving the link if we think that the link will not harm us or any affiliated business of ours. Softtik can also support the link insertion if the website doesn’t have any negative records with us and benefits us. If you fall in this category, kindly contact us at: infoa@softtik.com. </p>
                                                        <p>No one can use our company logo or artwork for their website. Eligible firms and companies can link to our website using:</p>
                                                        <ol>
                                                            <li>Organization Name.</li>
                                                            <li>The content they are linking makes sense in this context.</li>
                                                            <li>Uniform resource locator being linked.</li>
                                                        </ol>
                                                        <h4>iFrames </h4>
                                                        <p>No one can create frames around Softtik Technologies’ website that changes the design or appearance unless you have written permission.</p>
                                                        <h4>Content Liability</h4>
                                                        <p>It is announced publicly that we shall not be held liable for the content on Softtik’s website. You agree to defend and protect us all against claims rising on your website. No link on your website that can be taken as criminal, libelous, or violates third-party rights should be inserted. </p>

                                                        <h4>Reservations of Rights</h4>
                                                        <p> Softtik Technologies holds the right to ask you to remove all or any specific links to our website. You also agree to remove the links when requested by Softtik. You are agreeing to follow these linking terms and conditions.</p>

                                                        <h4>Link Removal From Our Website</h4>
                                                        <p>You have the right to ask for the removal of any link that may seem inappropriate or offensive in any way. We might consider your request, but we are not obliged to do so. </p>
                                                        <p>We do not claim or ensure that the material of this website is correct or complete. We also don’t claim that the website will be up forever and the content will stay updated.</p>

                                                        <h4>Disclaimer</h4>
                                                        <p>Softtik excludes all conditions, claims, and representation on our website to the maximum extent the law allows. Not a single word in these terms and conditions will exclude or limit our or your liability for:</p>

                                                        <ul className="list-about style-two">
                                                            <li>Personal injury or death</li>
                                                            <li>Fraud </li>
                                                            <li>Situations in which law is applicable</li>
                                                        </ul>

                                                        <p>Softtik will not be held responsible for any type of damages or loss as long as the information on the website is free of cost. </p>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </main>
                </InnerLayout >
            </>
        )
    };
};

export default TermsConditions;